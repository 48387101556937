import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as environment from '../../../environments/environment';
import * as sha256 from 'hash.js/lib/hash/sha/256';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {

  login: string = null;
  password: string = null;
  baseUrl: string;
  // baseUrl = 'https://via-net.de/otis-test-iplan4-rest-backend-0-9/v1/1/';
  // baseUrl = 'https://via-net.de/demo1-iplan4-rest-backend-0-8/v1/1/';
  headers = new HttpHeaders();

  constructor(private http: HttpClient) {}

  setHeaders(login: string, password?: string): void {
    this.login = login;
    if (!password) {
      this.password = environment.getPassword(login);
    } else {
      // prod
      this.password =  sha256().update(`%!${password}?$`).digest('hex').toUpperCase();
    }

    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      login: this.login,
      password: this.password,
    });
  }

  async logIn(login: string, password?: string): Promise<{ userId: string; login: string; password: string }> {
    this.setBaseURL()
    if (password) {
      this.setHeaders(login, password);
    } else {
      // prod
      this.setHeaders(login);
    }
    return await this.http
      .get(`${this.baseUrl}auth/login`, { headers: this.headers })
      .toPromise()
      .then((res: any) => {
        return {
          userId: res.user.user_id,
          login,
          password: this.password,
        };
      });
  }

  logout(): void {
    this.login = null;
    this.password = null;
    sessionStorage.clear();
  }

  isLoggedIn(): boolean {
    if (
      this.login !== null ||
      (sessionStorage.getItem('userId') !== null &&
        sessionStorage.getItem('login') !== null &&
        sessionStorage.getItem('password') !== null)
    ) {
      return true;
    } else {
      return false;
    }
  }

  userId(): string {
    return sessionStorage.getItem('userId');
  }

  getHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      login: sessionStorage.getItem('login'),
      password: sessionStorage.getItem('password'),
    });
  }

  getLinksFromStorage(){
    const defaultLinks = [
      {
        url:'https://via-net.de/iplan-mobile-demo-1-0/v1/1/',
        selectedURL: true
      }
    ];
    const links = localStorage.getItem('links');
    if(links) {
      return JSON.parse(links);
    } else {
      localStorage.setItem('links',JSON.stringify(defaultLinks));
      return defaultLinks;
    }
  }

  setBaseURL(){
    this.baseUrl = this.getLinksFromStorage().find(x=>x.selectedURL).url
  }
  
  
}
